import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import _foreach from 'lodash/forEach'

import DefaultLayout from '@/coreui/layouts/Default'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { requiresAuth: true, allowed: ['ROLE_USER'] }
      }
    ]
  },
  {
    path: '/security',
    name: 'Security',
    redirect: '/security/login',
    component: {
      render () {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/Login.vue')
      },
      {
        path: 'logout',
        name: 'Logout',
        component: () => import('@/views/Logout.vue')
      },
      {
        path: 'password',
        name: 'Password',
        component: () => import('@/views/Password.vue')
      }
    ]
  },
  {
    path: '/error',
    name: 'Error',
    redirect: '/error/403',
    component: {
      render () {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '403',
        name: 'Page403',
        component: () => import('@/views/Page403.vue')
      }
    ]
  },
  {
    path: '/profile',
    redirect: '/profile/password',
    name: 'Profil',
    component: DefaultLayout,
    children: [
      {
        path: 'password',
        name: 'Mein Passwort Ändern',
        component: () => import('@/views/Profile/Password'),
        meta: { requiresAuth: true, allowed: ['ROLE_USER'] }
      }
    ]
  },
  {
    path: '/pflegedienst',
    redirect: '/pflegedienst/uebersicht',
    name: 'Pflegedienst',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Pflegedienst-Verwaltung - Übersicht',
        component: () => import('@/views/Pflegedienst/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Pflegedienst Verwaltung - Neuer Pflegedienst',
        component: () => import('@/views/Pflegedienst/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Pflegedienst Verwaltung Editieren',
        component: () => import('@/views/Pflegedienst/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'mitarbeiter/:uuid',
        name: 'Pflegedienst Verwaltung - Mitarbeiter',
        component: () => import('@/views/Pflegedienst/Mitarbeiter'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/krankenkasse',
    redirect: '/krankenkasse/uebersicht',
    name: 'Krankenkassen',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Krankenkassen-Verwaltung - Übersicht',
        component: () => import('@/views/Krankenkasse/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Krankenkassen-Verwaltung Neue Krankenkasse',
        component: () => import('@/views/Krankenkasse/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Krankenkassen-Verwaltung Editieren',
        component: () => import('@/views/Krankenkasse/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/mitarbeiter',
    redirect: '/mitarbeiter/uebersicht',
    name: 'Mitarbeiter',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Mitarbeiterverwaltung - Übersicht',
        component: () => import('@/views/Mitarbeiter/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Mitarbeiterverwaltung - Neuer Mitarbeiter',
        component: () => import('@/views/Mitarbeiter/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'passwort/:uuid',
        name: 'Mitarbeiterverwaltung Passwort ändern',
        component: () => import('@/views/Mitarbeiter/Passwort'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Mitarbeiter Editieren',
        component: () => import('@/views/Mitarbeiter/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/benutzer',
    redirect: '/benutzer/uebersicht',
    name: 'Benutzer',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Benutzerverwaltung - Übersicht',
        component: () => import('@/views/Benutzer/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Benutzerverwaltung - Neuer Benutzer',
        component: () => import('@/views/Benutzer/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'passwort/:uuid',
        name: 'Mitarbeiter Passwort ändern',
        component: () => import('@/views/Benutzer/Passwort'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Mitarbeiter Editieren',
        component: () => import('@/views/Benutzer/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/pflegedienst-angestellte',
    redirect: '/pflegedienst-angestellte/uebersicht',
    name: 'PflegedienstAngestellte',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Pflegedienste - Übersicht',
        component: () => import('@/views/PflegedienstAngestellte/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'PflegedienstAngestellte - Neuer PflegedienstAngestellte',
        component: () => import('@/views/PflegedienstAngestellte/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'PflegedienstAngestellte Editieren',
        component: () => import('@/views/PflegedienstAngestellte/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/patient',
    redirect: '/patient/uebersicht',
    name: 'Patienten',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Patient - Übersicht',
        component: () => import('@/views/Patient/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Patient - Neuer Patient',
        component: () => import('@/views/Patient/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Patienten Editieren',
        component: () => import('@/views/Patient/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/pflegende',
    redirect: '/pflegende/uebersicht',
    name: 'Pflegende',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Pflegende-Verwaltung - Übersicht',
        component: () => import('@/views/Pflegende/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Pflegende-Verwaltung - Neuer Pflegender',
        component: () => import('@/views/Pflegende/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'passwort/:uuid',
        name: 'Pflegende-Verwaltung Passwort ändern',
        component: () => import('@/views/Pflegende/Passwort'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Pflegende-Verwaltung Editieren',
        component: () => import('@/views/Pflegende/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/pflegedienst-angestellte',
    redirect: '/pflegedienst-angestellte/uebersicht',
    name: 'Pflegedienst Angestellte',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Pflegedienst Angestellte-Verwaltung - Übersicht',
        component: () => import('@/views/PflegedienstAngestellte/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Pflegedienst Angestellte-Verwaltung - Neuer Pflegender',
        component: () => import('@/views/PflegedienstAngestellte/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'passwort/:uuid',
        name: 'Pflegedienst Angestellte-Verwaltung Passwort ändern',
        component: () => import('@/views/PflegedienstAngestellte/Passwort'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Pflegedienst Angestellte-Verwaltung Editieren',
        component: () => import('@/views/PflegedienstAngestellte/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/bestellung',
    redirect: '/bestellung/uebersicht',
    name: 'Bestellungen',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Bestellungen - Übersicht',
        component: () => import('@/views/Bestellung/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Bestellungen - Neue Bestellung',
        component: () => import('@/views/Bestellung/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Bestellungen Editieren',
        component: () => import('@/views/Bestellung/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'drucken/:uuid',
        name: 'Bestellung Drucken',
        component: () => import('@/views/Bestellung/Drucken'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'anlage2/:uuid',
        name: 'Bestellung Drucken Anlage2',
        component: () => import('@/views/Bestellung/Anlage2'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'anlage2-wiederverwendbar/:uuid',
        name: 'Bestellung Drucken Anlage2 W',
        component: () => import('@/views/Bestellung/Anlage2-Wiederverwendbar'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'anlage2-ohne-wiederverwendbar/:uuid',
        name: 'Bestellung Drucken Anlage2 ow',
        component: () => import('@/views/Bestellung/Anlage2-ohneWiederverwendbar'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/produkt',
    redirect: '/produkt/uebersicht',
    name: 'Produkte',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Produkte - Übersicht',
        component: () => import('@/views/Produkt/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Produkte - Neue Bestellung',
        component: () => import('@/views/Produkt/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Produkte Editieren',
        component: () => import('@/views/Produkt/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/konfiguration',
    redirect: '/konfiguration/uebersicht',
    name: 'Konfiguration',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Konfiguration - Übersicht',
        component: () => import('@/views/Config/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Konfiguration - Neue Konfiguration',
        component: () => import('@/views/Config/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:id',
        name: 'Konfiguration Editieren',
        component: () => import('@/views/Config/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/texte',
    redirect: '/texte/uebersicht',
    name: 'Texte',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Texte - Übersicht',
        component: () => import('@/views/Text/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Texte - Neuer Text',
        component: () => import('@/views/Text/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:id',
        name: 'Texte Editieren',
        component: () => import('@/views/Text/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/lieferungen',
    redirect: '/lieferungen/uebersicht',
    name: 'Lieferungen',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Lieferungen - Übersicht',
        component: () => import('@/views/Lieferungen/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Lieferungen - Neue Lieferung',
        component: () => import('@/views/Lieferungen/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Lieferung Editieren',
        component: () => import('@/views/Lieferungen/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/kosten-uebernahme-antraege',
    redirect: '/kosten-uebernahme-antraege/uebersicht',
    name: 'Kostenübernahme',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'Kostenübernahme - Übersicht',
        component: () => import('@/views/KostenUebernahmeAntrag/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'Kostenübernahme - Neue Kostenübernahme',
        component: () => import('@/views/KostenUebernahmeAntrag/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'Kostenübernahme Editieren',
        component: () => import('@/views/KostenUebernahmeAntrag/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'drucken/:uuid',
        name: 'Kostenübernahme Drucken',
        component: () => import('@/views/KostenUebernahmeAntrag/Drucken'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/faq',
    redirect: '/FAQ/uebersicht',
    name: 'FAQ',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'FAQ - Übersicht',
        component: () => import('@/views/FAQ/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'FAQ - Neues FAQ Element',
        component: () => import('@/views/FAQ/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'FAQ Editieren',
        component: () => import('@/views/FAQ/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  },
  {
    path: '/testing',
    redirect: '/testing/uebersicht',
    name: 'DB-Testing',
    component: DefaultLayout,
    children: [
      {
        path: 'uebersicht',
        name: 'DB-Testing - Übersicht',
        component: () => import('@/views/Testing/Index'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'neu',
        name: 'DB-Testing - Neuer Datensatz',
        component: () => import('@/views/Testing/Neu'),
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      },
      {
        path: 'editieren/:uuid',
        name: 'DB-Testing - Datensatz Editieren',
        component: () => import('@/views/Testing/Edit'),
        props: true,
        meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () { return { top: 0 } }
})

//   ____  _____ _____ ____  _     ___ _   _ _  __   ___  _   _ _   _ _____      _   _   _ _____ _   _
//  |  _ \| ____| ____|  _ \| |   |_ _| \ | | |/ /  / _ \| | | | \ | | ____|    / \ | | | |_   _| | | |
//  | | | |  _| |  _| | |_) | |    | ||  \| | ' /  | | | | |_| |  \| |  _|     / _ \| | | | | | | |_| |
//  | |_| | |___| |___|  __/| |___ | || |\  | . \  | |_| |  _  | |\  | |___   / ___ \ |_| | | | |  _  |
//  |____/|_____|_____|_|   |_____|___|_| \_|_|\_\  \___/|_| |_|_| \_|_____| /_/   \_\___/  |_| |_| |_|
//
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['security/isAuthenticated']) {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})
//   ____   ___  _     _____ ____
//  |  _ \ / _ \| |   | ____/ ___|
//  | |_) | | | | |   |  _| \___ \
//  |  _ <| |_| | |___| |___ ___) |
//  |_| \_\\___/|_____|_____|____/
//
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.allowed)) {
    if (store.getters['security/hasRole']('ROLE_ADMIN')) {
      next()
    } else {
      let hasRole = false
      _foreach(to.meta.allowed, function (role) {
        if (store.getters['security/hasRole'](role)) {
          hasRole = true
        }
      })
      if (!hasRole) {
        next({ name: 'Page403' })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

//   ____   _    ____ _____   _____ ___ _____ _     _____ ____
//  |  _ \ / \  / ___| ____| |_   _|_ _|_   _| |   | ____/ ___|
//  | |_) / _ \| |  _|  _|     | |  | |  | | | |   |  _| \___ \
//  |  __/ ___ \ |_| | |___    | |  | |  | | | |___| |___ ___) |
//  |_| /_/   \_\____|_____|   |_| |___| |_| |_____|_____|____/
//
router.beforeEach((to, from, next) => {
  document.title = 'Weinhandel - ' + to.name
  next()
})

export default router
