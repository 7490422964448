export default [
  {
    component: 'CNavItem',
    name: 'Übersicht',
    to: '/dashboard',
    header: true,
    sidebar: true,
    icon: 'cil-speedometer'
  },
  {
    component: 'CNavItem',
    name: 'Bestellungen',
    to: '/bestellung/uebersicht',
    icon: 'cil-cart',
    header: true,
    sidebar: true,
    allowed: ['ROLE_ADMIN']
  },
  {
    component: 'CNavItem',
    name: 'Lieferungen',
    to: '/lieferungen/uebersicht',
    icon: 'cil-cart',
    header: true,
    sidebar: true,
    allowed: ['ROLE_ADMIN']
  },
  {
    component: 'CNavItem',
    name: 'Anträge',
    to: '/kosten-uebernahme-antraege',
    icon: 'cil-credit-card',
    header: false,
    sidebar: false,
    allowed: ['ROLE_ADMIN'],
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        header: false,
        sidebar: false,
        to: '/kosten-uebernahme-antraege/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'neuer Antrag',
        header: false,
        sidebar: false,
        to: '/kosten-uebernahme-antraege/neu'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Kunden',
    to: '/patient/uebersicht',
    icon: 'cil-address-book',
    allowed: ['ROLE_ADMIN'],
    header: true,
    sidebar: true
  },
  {
    component: 'CNavItem',
    name: 'Pflegende',
    to: '/pflegende',
    icon: 'cil-address-book',
    allowed: ['ROLE_ADMIN'],
    header: false,
    sidebar: false,
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        header: false,
        sidebar: false,
        to: '/pflegende/uebersicht'

      },
      {
        component: 'CNavItem',
        name: 'Neuer Pflegender',
        header: false,
        sidebar: false,
        to: '/pflegende/neu'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Winzer',
    to: '/pflegedienst/uebersicht',
    icon: 'cil-hospital',
    allowed: ['ROLE_ADMIN'],
    header: true,
    sidebar: true
  },
  {
    component: 'CNavItem',
    name: 'Pflegedienst-Angestellte',
    to: '/pflegedienst-angestellte',
    icon: 'cil-address-book',
    allowed: ['ROLE_ADMIN'],
    sidebar: false,
    header: false,
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        sidebar: false,
        header: false,
        to: '/pflegedienst-angestellte/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'Neuer Pflegedienst-Angestellter',
        sidebar: false,
        header: false,
        to: '/pflegedienst-angestellte/neu'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Krankenkassen',
    to: '/krankenkasse',
    icon: 'cil-hospital',
    allowed: ['ROLE_ADMIN'],
    header: false,
    sidebar: false,
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        header: false,
        sidebar: false,
        to: '/krankenkasse/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'Neue Krankenkasse',
        header: false,
        sidebar: false,
        to: '/krankenkasse/neu'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Texte',
    to: '/texte/uebersicht',
    icon: 'cil-info',
    header: false,
    sidebar: true,
    sidebarfooter: true,
    allowed: ['ROLE_ADMIN']
  },
  {
    component: 'CNavItem',
    name: 'Konfiguration',
    to: '/konfiguration/uebersicht',
    icon: 'cil-info',
    header: false,
    sidebar: true,
    sidebarfooter: true,
    allowed: ['ROLE_ADMIN']
  },
  {
    component: 'CNavItem',
    name: 'Mitarbeiter',
    to: '/personal',
    icon: 'cil-address-book',
    allowed: ['ROLE_ADMIN'],
    sidebar: false,
    sidebarfooter: false,
    header: false,
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        sidebar: false,
        sidebarfooter: false,
        header: false,
        to: '/mitarbeiter/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'Neuer Mitarbeiter',
        sidebarfooter: false,
        sidebar: false,
        header: false,
        to: '/mitarbeiter/neu'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Alle Benutzer',
    to: '/benutzer',
    icon: 'cil-address-book',
    allowed: ['ROLE_ADMIN'],
    sidebarfooter: true,
    sidebar: true,
    header: false,
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        sidebarfooter: true,
        sidebar: true,
        header: false,
        to: '/benutzer/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'Neuer Benutzer',
        sidebarfooter: true,
        sidebar: true,
        header: false,
        to: '/benutzer/neu'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Produkte',
    to: '/produkt',
    header: false,
    sidebar: true,
    icon: 'cil-list-rich',
    allowed: ['ROLE_ADMIN'],
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        header: false,
        sidebar: true,
        to: '/produkt/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'Neues Produkt',
        header: false,
        sidebar: true,
        to: '/produkt/neu'
      }
    ]
  }
]
