<template>
  <CSidebar
    position="fixed"
    :unfoldable="smallSidebar"
    :visible="showSidebar"
  >
    <CSidebarBrand>
      <img :src="logo" style="height: 100px; width: auto; margin: auto;" alt="Cows Online" class="sidebar-brand-full" />
      <img :src="sygnet" alt="Cows Online" class="sidebar-brand-narrow" />
    </CSidebarBrand>
    <AppSidebarNav />

    <div class="">
      <hr/>
      <ul class="sidebar-nav">
        <li class="nav-item">
          <a href="" @click="$router.go(-1)" title="zurück" class="nav-link"><CIcon class="nav-icon" size="lg" icon="cil-arrow-thick-left" />zurück</a>
        </li>
      </ul>
      <hr/>
      <AppSidebarNavFooter />
    </div>
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="toggleSmallSidebar"
    />
  </CSidebar>
</template>

<script>
import { AppSidebarNav } from './AppSidebarNav'
import { AppSidebarNavFooter } from './AppSidebarNavFooter'
import logo from '@/assets/brand/logo.png'
import sygnet from '@/assets/brand/cows-sygnet-white.svg'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
    AppSidebarNavFooter
  },
  setup () {
    return {
      logo,
      sygnet
    }
  },
  computed: {
    smallSidebar () {
      return this.$store.getters['coreui/smallSideBar']
    },
    showSidebar () {
      return this.$store.getters['coreui/showSideBar']
    }
  },
  methods: {
    toggleSmallSidebar () {
      this.$store.commit('coreui/TOGGLE_SMALL_SIDEBAR')
    }
  }
}
</script>
<style lang="scss" scoped>
.container-lg {
  display: flex;
  flex-direction: column;
  min-height: 90vh; /* Set the container to full viewport height */
}

.content {
  flex: 1; /* Grow and take the available vertical space */
  /* Optional styles for the content */
}
.content-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1; /* Grow and take the available vertical space */
  /* Optional styles for the content */
}

li.btn-block {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
